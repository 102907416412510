<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import {getPartner, getPartnerUnsettledDiscounts, settleAccount} from "@/services/partner.service";
import Swal from "sweetalert2";
import * as XLSX from 'xlsx/xlsx.mjs'

export default {
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Estado de cuenta",
      items: [
        {
          text: "MaxParking"
        },
        {
          text: "Socios",
        },
        {
          text: "Estado de cuenta",
          active: true
        }
      ],
      unsettled: [],
      accountTotal: 0.00,
      partner: {
        username: '',
        password: '',
        parking_id: ''
      }
    }
  },
  async mounted() {
    const p = await getPartner(this.$route.params.id)
    this.partner = p.data.partner
    const pendingDiscounts = await getPartnerUnsettledDiscounts(this.$route.params.id)
    this.unsettled = pendingDiscounts.data.discounts
  },
  methods: {
    totalOnRender() {
      return this.unsettled.reduce((a, c) => a + c.amount, 0)
    },
    formatNumber(num) {
      if (num < 10) {
        return `0${num}`
      }
      return num
    },
    formatTicketId(input) {
      return input.substr(input.length - 5).toUpperCase()
    },
    formatDate(input) {
      const d = new Date(input)

      return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}  ${this.formatNumber(d.getHours())}:${this.formatNumber(d.getMinutes())}`
    },
    exportToExcel() {
      if (this.unsettled.length > 0) {
        const table = document.getElementById('report-table')

        const workbook = XLSX.utils.table_to_book(table)
        const ws = workbook.Sheets["Descuentos"]
        XLSX.utils.sheet_add_aoa(ws, [["Created " + new Date().toISOString()]], {origin: -1})

        const d =  new Date().toISOString()
        XLSX.writeFile(workbook, `descuentos-${this.partner.username}-${d}.xls`)
      } else {
        Swal.fire('error', 'No se ha generado ningun reporte')
      }
    },
    settleAccount() {
      Swal.fire({
        title: "¿Esta seguro?",
        text: "Asegurese de haber descargado el reporte de descuentos y de haber generado el cobro correspondiente con su socio antes de generar el cierre. ¿Desea proceder?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Si, cuadremos!",
        cancelButtonText: "No"
      }).then(async (result) => {
        if (result.value) {
          await settleAccount(this.partner._id)
          await Swal.fire("Corte Generado!", "Se limpia el estado de cuenta para el siguiente ciclo.", "success");
          const pendingDiscounts = await getPartnerUnsettledDiscounts(this.$route.params.id)
          this.unsettled = pendingDiscounts.data.discounts
        }
      })
    }
  }
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items"/>

    <div class="row">
      <div class="col-xl-12">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <div>
                <a href="/partners" class="btn btn-danger">Regresar</a>
                <hr/>
                <h1>{{ this.partner.username }}</h1>
                <hr/>
                <div class="col-lg-12 mb-3">
                  <button @click="exportToExcel" class="btn btn-primary">
                    <i class="ri-file-excel-line"></i>
                    Excel
                  </button>
                  |
                  <button class="btn btn-success" @click="settleAccount()">
                      <i class="ri-money-dollar-circle-fill"></i>
                      Generar Corte
                  </button>
                </div>
                <table class="table table-hover" id="report-table">
                  <thead>
                  <tr>
                    <th>Ticket</th>
                    <th>Fecha</th>
                    <th>Monto Descontado</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(item, index) in this.unsettled" :key="index">
                    <td>{{ formatTicketId(item.ticket_id) }}</td>
                    <td>{{ formatDate(item.date) }}</td>
                    <td>Q.{{ item.amount }}</td>
                  </tr>
                  <tr>
                    <td colspan="2"><b>TOTAL:</b></td>
                    <td><b>Q.{{totalOnRender()}}</b></td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>